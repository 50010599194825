import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ButtonLink } from "../components/button-link"
import { Link } from "gatsby"
interface MostPopularServicesProps {
  i18n: any
  data: any
  services: any
  classes?: string
}

export function MostPopularServices({
  services,
  classes,
  i18n,
}: MostPopularServicesProps) {
  return (
    <section className={classes}>
      <h2 className="text-5xl font-bold text-center text-neutral-600">
        {i18n.common.mostPopularServices}
      </h2>
      <div className="flex flex-col items-center justify-center gap-2 mt-12 mb-6 md:flex-row md:items-start">
        {services.map((service) => {
          return (
            <div key={service.id} className="flex">
              <Link
                className="max-w-sm pb-2 mx-4 my-2 no-underline cursor-pointer group hover:no-underline"
                to={`${i18n.slugs.services}#${service.id}`}
              >
                <GatsbyImage
                  className="rounded-lg drop-shadow-lg max-w-[20rem] transition duration-300 group-hover:drop-shadow-xl"
                  objectFit="contain"
                  alt=""
                  image={service.image.gatsbyImageData}
                  loading="lazy"
                />
                <h2 className="mx-auto text-2xl font-bold text-center transition duration-300 group-hover:shadow-linkUnderline text-neutral-500 mt-7 w-fit">
                  {service.title}
                </h2>
              </Link>
            </div>
          )
        })}
      </div>
      <ButtonLink
        slug={i18n.slugs.services}
        text={i18n.common.seeAllServices}
        classes="block w-fit m-auto"
      />
    </section>
  )
}
