import * as React from "react"
import { ProductCard } from "./product-card"

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({
  products = [],
  classes = "justify-center",
  i18n,
}) {
  return (
    <div
      className={`${classes} flex flex-col items-center gap-2 m-auto out-wrapper`}
    >
      {products.map((p, index) => (
        <ProductCard product={p} key={p.id} eager={index === 0} i18n={i18n} />
      ))}
    </div>
  )
}
