import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../utils/format-price"

export function ProductCard({ product, eager, i18n }) {
  const {
    title,
    priceRangeV2,
    variants,
    handle,
    media: [firstImage],
    vendor,
    storefrontImages,
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )
  const compareAtPrice = variants[0].compareAtPrice
    ? formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        variants[0].compareAtPrice
      )
    : null

  const defaultImageHeight = 200
  const defaultImageWidth = 200
  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: "fixed",
        width: defaultImageWidth,
        height: defaultImageHeight,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const hasImage =
    firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length
  const isPrim25 = handle === "1-25mm-slackline-kits"

  return (
    <Link
      className={`${
        isPrim25 ? "mt-8 flex-shrink-0 " : ""
      } max-w-sm pb-2 m-2 no-underline cursor-pointer hover:no-underline group`}
      to={`${i18n.slugs.products}${handle}`}
      aria-label={`View ${title} product page`}
    >
      {hasImage ? (
        // kossé ke c'tait ça?: data-name="product-image-box"
        <div>
          {/* Custom hardcoded image 
          {isPrim25 && 1 === 1 ? ( 
            i18n.common.currentLang === "en" ? ( 
              <StaticImage src={`../assets/images/prim_25_en.jpg`} alt="" /> 
            ) : ( 
              <StaticImage src="../assets/images/prim_25_fr.jpg" alt="" /> 
            ) 
          ) : ( */}
            <GatsbyImage
              className="transition duration-300 rounded-lg drop-shadow-lg group-hover:drop-shadow-xl"
              objectFit="contain"
              alt={firstImage?.altText ?? title}
              image={firstImage?.image?.gatsbyImageData ?? storefrontImageData}
              loading={eager ? "eager" : "lazy"}
            />
          {/* )} */}
        </div>
      ) : (
        <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
      )}
      <div className="flex flex-col items-center font-bold">
        <h3 className="text-2xl transition duration-300 text-neutral-500 group-hover:shadow-linkUnderline">
          {title}
        </h3>
        <div className="text-sm text-neutral-400">
          {i18n.common.from} {vendor}
        </div>
        <div
          className={`mt-3 text-lg ${
            compareAtPrice ? "text-primary-800" : "test-neutral-500"
          }`}
        >
          {price}
        </div>
        {compareAtPrice && (
          <p className="text-sm text-red-400 line-through">{compareAtPrice}</p>
        )}
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    handle
    # slug: gatsbyPath(
    # filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    # )
    media {
      ... on ShopifyMediaImage {
        image {
          altText
          gatsbyImageData(aspectRatio: 1, width: 640)
        }
      }
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    variants {
      compareAtPrice
    }
    vendor
  }
`
