import React from "react"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

interface YouTubeProps {
  videoID: string
  title: string
}

export const YouTube: React.FC<YouTubeProps> = function (props: YouTubeProps) {
  const { videoID, title } = props

  return (
    <LiteYouTubeEmbed
      id={videoID}
      title={title}
      // lazy-loadé ça serait nice https://github.com/ibrahimcesar/react-lite-youtube-embed/issues/49
      webp={true}
    />
  )
}
