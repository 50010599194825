import * as React from "react"
import { ProductListing } from "../components/product-listing"
import { ButtonLink } from "../components/button-link"

interface MostPopularKitsProps {
  i18n: any
  products: any
  showTitle: boolean
  classes?: string
}

export function MostPopularKits({
  i18n,
  products,
  showTitle,
  classes,
}: MostPopularKitsProps) {
  return (
    <section className={classes}>
      {showTitle && (
        <h2 className="text-5xl font-bold text-center text-neutral-600">
          {i18n.common.mostPopularProducts}
        </h2>
      )}
      <ProductListing products={products} i18n={i18n} />
      <ButtonLink
        slug={i18n.slugs.products}
        text={i18n.common.seeAllProducts}
        classes="block w-fit m-auto mt-6"
      />
    </section>
  )
}
