import React, { useState } from "react"
import { Spinner } from "./spinner"

interface FormProps {
  i18n: any
}

export function Form({ i18n }: FormProps) {
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")

  async function submit(e: React.SyntheticEvent) {
    e.preventDefault()

    try {
      if (!email) {
        return setError(i18n.common.newsletter.noEmail)
      }
      setIsFormSubmit(true)
      setError("")
      const response = await fetch(`/.netlify/functions/newsletter`, {
        method: `POST`,
        body: JSON.stringify({ email }),
        headers: {
          "content-type": `application/json`,
        },
      })
      const res = await response.text()
      if (res === "SUBSCRIBED") {
        setSuccess(true)
      } else if (res === "MEMBER_EXISTS_WITH_EMAIL_ADDRESS") {
        setError(i18n.common.newsletter.alreadyExist)
      } else {
        setError(i18n.common.newsletter.unknown)
      }
    } catch (error: any) {
      console.log("@error: ", error)
      setError(i18n.common.newsletter.unknown)
    } finally {
      setIsFormSubmit(false)
    }
  }

  return (
    <form
      className="w-full max-w-lg"
      onSubmit={submit}
      aria-busy={isFormSubmit}
    >
      <div>
        <label htmlFor="email" className="text-lg">
          {i18n.common.newsletter.yourEmail}
        </label>
        <input
          id="email"
          type="email"
          className="block max-w-full px-4 py-2 mb-6 text-lg rounded w-96"
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <p className="text-2xl font-bold text-red-900">{error}</p>}
      </div>
      <div className="max-w-full w-96">
        {success ? (
          <p className="text-xl font-bold">
            👍 {i18n.common.newsletter.success}
          </p>
        ) : (
          <button
            className={`${
              isFormSubmit ? "grayscale-[80%]" : ""
            } relative hover:bg-primary-500 bg-primary-600 max-w-full px-8 py-2 mx-auto text-2xl font-bold text-center text-white transition-colors rounded block`}
            disabled={isFormSubmit}
            placeholder={i18n.common.newsletter.emailPlaceholder}
            type="submit"
          >
            {i18n.common.newsletter.submitButton}
            {isFormSubmit && (
              <Spinner aria-label={i18n.common.loading} show={isFormSubmit} />
            )}
          </button>
        )}
      </div>
    </form>
  )
}
