import * as React from "react"

interface HeroProps {
  children?: any
  title?: string
}

export function Hero({ title, children }: HeroProps) {
  return (
    <div className="min-h-[80vh] relative flex items-center justify-center text-neutral-100">
      {children}
      <h1 className="z-[5] max-w-4xl break-words font-bold text-center uppercase">
        {title}
      </h1>
    </div>
  )
}
