import * as React from "react"
import { Hero } from "../components/hero"
import { YouTube } from "../components/youtube"
import { Newsletter } from "../components/newsletter"
import { MostPopularKits } from "../components/most-popular-kits"
import { MostPopularServices } from "../components/most-popular-services"
import { BigLinks } from "../components/big-links"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export default function IndexPage({ data, pageContext }) {
  const { i18n } = pageContext

  return (
    <>
      <Hero title={i18n.home.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_max.jpg"
          alt=""
          quality={95}
        />
      </Hero>
      <div className="out-wrapper">
      <section className="max-w-3xl mx-auto my-10 text-center">
        <h2 className="mb-6 text-5xl font-bold text-center text-neutral-600">{i18n.products.courseTitle}</h2>
        <a href="https://slacklinemastery.com">
          <div>
            <StaticImage
              src={`../../assets/images/slackline_mastery.jpg`}
              alt=""
            />
          </div>

          <div className="flex flex-col items-center font-bold">
            <h3 className="mt-2 text-2xl transition duration-300 text-neutral-500 group-hover:shadow-linkUnderline">
              The Slackline Mastery Course Bundle
            </h3>
            <div className="text-sm text-neutral-400">
              {i18n.products.courseDescription}
            </div>
          </div>
        </a>
        <p className={`mt-3 text-lg`}>{i18n.products.courseSubTitle}</p>
      </section>
        <MostPopularKits
          i18n={i18n}
          products={data.shopifyCollection.products}
          showTitle
          classes="mt-20"
        />
        <MostPopularServices
          i18n={i18n}
          services={data.allDatoCmsService.nodes}
          classes="mt-20"
        />
        <div className="max-w-4xl mx-auto mt-20">
          <YouTube videoID="LMttk0xRRYk" title="Video demo" />
        </div>
        <BigLinks i18n={i18n} />
      </div>
      <Newsletter i18n={i18n} />
    </>
  )
}

export const query = graphql`
  query ($lang: String) {
    shopifyCollection(handle: { eq: "frontpage" }) {
      products {
        ...ProductCard
      }
    }

    allDatoCmsService(filter: { locale: { eq: $lang }, order: { lt: 4 } }) {
      nodes {
        id
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(aspectRatio: 1)
        }
      }
    }
  }
`
