import React from "react"
import { ImSpinner2 } from "@react-icons/all-files/im/ImSpinner2"

interface FormProps {
  show: boolean
}

export function Spinner({ show }: FormProps) {
  return (
    <span>
      <ImSpinner2
        className="animate-spin w-8 h-8 absolute top-[20%] left-[40%]"
        aria-hidden="true"
      />
    </span>
  )
}
