import * as React from "react"
import { BigLink } from "./big-link"
import { StaticImage } from "gatsby-plugin-image"
interface BigLinksProps {
  i18n: any
  isContactPage?: boolean
}

export function BigLinks({ i18n, isContactPage }: BigLinksProps) {
  const imageClasses = "w-full h-full"
  const links = [
    {
      text: i18n.common.bigLinks.shop,
      slug: i18n.slugs.products,
      image: (
        <StaticImage
          className={imageClasses}
          src="../assets/images/banner_feet.jpg"
          alt=""
        />
      ),
    },
    {
      text: i18n.common.bigLinks.services,
      slug: i18n.slugs.services,
      image: (
        <StaticImage
          className={imageClasses}
          src="../assets/images/banner_heidi.jpg"
          objectPosition="78% 50%"
          alt=""
        />
      ),
      classNames: "",
    },
    // {
    //   text: i18n.common.bigLinks.lessons,
    //   slug: i18n.slugs.lessons,
    //   image: (
    //     <StaticImage
    //       className={imageClasses}
    //       src="../assets/images/banner_max.jpg"
    //       alt=""
    //     />
    //   ),
    // },
  ]
  if (!isContactPage) {
    links.push({
      text: i18n.common.bigLinks.contact,
      slug: i18n.slugs.contact,
      image: (
        <StaticImage
          className={imageClasses}
          src="../assets/images/banner_split.jpg"
          objectPosition="70% 50%"
          alt=""
        />
      ),
      classNames: "md:col-span-2",
    })
  }

  return (
    <div className="grid gap-4 my-20 md:grid-cols-2 place-content-center out-wrapper">
      {links.map(({ text, slug, image, classNames }) => {
        return (
          <BigLink
            slug={slug}
            text={text}
            image={image}
            key={slug}
            classNames={classNames}
          />
        )
      })}
    </div>
  )
}
