import * as React from "react"
import { Form } from "./form"
interface NewsletterProps {
  i18n?: any
}

export function Newsletter({ i18n }: NewsletterProps) {
  return (
    <section className="w-full py-8 bg-secondary-100">
      <div className="flex flex-wrap items-center justify-center w-full gap-7 out-wrapper">
        <div className="max-w-xl">
          <h2 className="text-4xl font-bold text-secondary-400">
            {i18n.common.newsletter.title} 📬
          </h2>
          <p className="mt-2 text-lg text-neutral-600">
            {i18n.common.newsletter.subTitle}
          </p>
        </div>
        <Form i18n={i18n} />
      </div>
    </section>
  )
}
