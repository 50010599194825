import * as React from "react"
import { Link } from "gatsby"

interface ButtonLinkProps {
  text: string
  slug: string
  classes?: string
}

export function ButtonLink({ text, slug, classes }: ButtonLinkProps) {
  const className = `${classes} px-4 py-3 text-lg font-bold border-2 rounded-lg border-secondary-300 text-secondary-300 hover:bg-secondary-300 hover:text-white transition duration-300 hover:no-underline`

  if (/https?:\/\//.test(slug)) {
    return (
      <a href={slug} className={className}>
        {text}
      </a>
    )
  }
  return (
    <Link to={slug} className={className}>
      {text}
    </Link>
  )
}
