import React from "react"
import { Link } from "gatsby"
import { CgChevronRight } from "@react-icons/all-files/cg/CgChevronRight"

interface BigLinkProps {
  slug: string
  text: string
  image: any
  classNames?: string
}

export function BigLink({ slug, text, image, classNames }: BigLinkProps) {
  return (
    <Link
      to={slug}
      className={`text-white group relative grid place-content-center min-w-[80vw] h-80 md:min-w-full max-w-lg ${
        classNames || ""
      }`}
    >
      <div className="absolute z-0 w-full h-full transition duration-300 group-hover:brightness-110">
        {image}
      </div>
      <span className="z-10 flex items-center text-3xl font-bold text-center uppercase sm:text-4xl">
        {text}
        <CgChevronRight className="w-8 h-8 transition-transform	duration-300 group-hover:translate-x-1.5" />
      </span>
    </Link>
  )
}
